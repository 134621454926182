<template>
  <div class="main">
    <div class="payment-sample content-body">
      <a-breadcrumb>
        <a-breadcrumb-item>
          <HomeOutlined />
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          Sample Payment
        </a-breadcrumb-item>
      </a-breadcrumb>
      <a-row class="body">
        <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="payment-type">
          <div class="header">Stripe Subscription</div>
          <div class="subscribe">
            <a-button type="primary" size="large" block class="login-btn" html-type="submit" @click="createCheckoutSession('price_1IdXr6DfrP2YlcFNnKZLuSPi')">
              Subscribe
            </a-button>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, reactive, UnwrapRef } from 'vue'
import { HomeOutlined } from '@ant-design/icons-vue'
import { notification } from 'ant-design-vue'

import subscriptionService from '../services/subscription'
import keyService from '../services/key'

declare const Stripe
export default defineComponent({
  name: 'Dashboard',
  components: {
    HomeOutlined
  },
  setup () {
    const paymentKeys = ref()
    const stripe = ref()
    const errorMessage = ref<string>('')
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const subscriptionCheckout = async (sessionId) => {
      const stripeKey = paymentKeys.value.filter(key => key.type === 'Stripe')
      if (stripeKey.length === 0) errorMessage.value = 'Stripe public key is not configured'
      stripe.value = Stripe(stripeKey[0].key)
      stripe.value.redirectToCheckout({ sessionId: sessionId })
    }
    const createCheckoutSession = async (priceId) => {
      try {
        const responce = await subscriptionService.createCheckoutSession({ priceId })
        console.log('responce', responce.data)
        subscriptionCheckout(responce.data.id)
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    const getGatewaySettings = async () => {
      try {
        const responce = await keyService.getKeys()
        paymentKeys.value = responce.data
        console.log('paymentKeys', paymentKeys.value)
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    onMounted(() => {
      getGatewaySettings()
    })
    return {
      createCheckoutSession
    }
  }
})
</script>
<style lang="scss">
.payment-sample{
  .body {
    .payment-type {
      background: #fff;
      padding: 15px;
      border-radius: 10px;
      min-height: 200px;
      .header {
        text-align: left;
        padding: 15px 0;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
}
</style>
