
import { defineComponent, ref, onMounted, reactive, UnwrapRef } from 'vue'
import { HomeOutlined } from '@ant-design/icons-vue'
import { notification } from 'ant-design-vue'

import subscriptionService from '../services/subscription'
import keyService from '../services/key'

declare const Stripe
export default defineComponent({
  name: 'Dashboard',
  components: {
    HomeOutlined
  },
  setup () {
    const paymentKeys = ref()
    const stripe = ref()
    const errorMessage = ref<string>('')
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const subscriptionCheckout = async (sessionId) => {
      const stripeKey = paymentKeys.value.filter(key => key.type === 'Stripe')
      if (stripeKey.length === 0) errorMessage.value = 'Stripe public key is not configured'
      stripe.value = Stripe(stripeKey[0].key)
      stripe.value.redirectToCheckout({ sessionId: sessionId })
    }
    const createCheckoutSession = async (priceId) => {
      try {
        const responce = await subscriptionService.createCheckoutSession({ priceId })
        console.log('responce', responce.data)
        subscriptionCheckout(responce.data.id)
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    const getGatewaySettings = async () => {
      try {
        const responce = await keyService.getKeys()
        paymentKeys.value = responce.data
        console.log('paymentKeys', paymentKeys.value)
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    onMounted(() => {
      getGatewaySettings()
    })
    return {
      createCheckoutSession
    }
  }
})
